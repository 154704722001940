.image {
  width: 100%;
  height: 25vw;
  object-fit: contain;
  margin-left: auto;
  margin-right: auto;
  animation: fadeIn 1s ease-in-out;
  transition: "opacity 0.5s ease-in-out";
}

@keyframes fadeIn {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

@media only screen and (max-width: 450px) {
  .image {
    height: 30rem;
  }
}
