.main {
  margin: 1rem 12rem;
}

.card {
  margin-right: 3rem;
  margin-left: 3rem;
  margin-bottom: 3rem;
}

.card1 {
  margin-right: 3rem;
  margin-left: 3rem;
  margin-bottom: 3rem;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);
}

.image {
  height: 12rem;
  width: 12rem;
  object-fit: cover;
}

.title {
  text-align: center;
  text-align: center;
  color: black;
  opacity: 0.7;
  font-size: 1.5rem;
  font-weight: 500;
}

@media (max-width: 700px) {
  .main {
    margin: 2rem 0;
  }
}
