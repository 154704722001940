.contact-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  gap: 1.5rem;
  border-radius: 0.5rem;
  margin-bottom: 50px;
}
.form-container {
  padding: 0.5rem;
  width: 27vw;
  background-color: #e8edf0;
}
.r1 {
  display: flex;
  align-items: center;
}
.informations {
  padding: 1rem;
}
.form-container h3 {
  font-size: 1.9rem;
  font-weight: 600;
  margin-bottom: 1rem;
  margin-bottom: 40px;
}
.contact-form {
  display: grid;
  row-gap: 1rem;
}
label {
  font-weight: 500;
}
.contact-form input,
.contact-form textarea {
  width: 100%;
  border: none;
  outline: none;
  padding: 0.5rem;
  font-size: 0.8rem;
  border-radius: 0.2rem;
  margin-bottom: 20px;
  font-family: Arial, Helvetica, sans-serif;
}
.contact-form textarea {
  resize: none;
  height: 145px;
}
.contact-form .send-button {
  border: none;
  outline: none;
  font-size: 0.8rem;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
  width: 27%;
  border-radius: 1rem;
  padding: 0.7rem;
  background: #191919;
  color: white;
}
.contact-form .send-button:hover {
  background: #ffe600;
  transition: 0.3s all linear;
  color: #191919;
}
.map {
  width: 60vw;
  height: 30vw;
}
.map iframe {
  width: 100%;
  height: 100%;
  border: none;
}
.contact-row {
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;
}
.row-info-contact {
  padding: 0 15px;
  margin: 0 auto;
  text-align: center;
}
.icons-details {
  text-align: center;
  font-size: 1.1rem;
  font-weight: 420;
}
.li {
  display: flex;
  align-items: center;
  gap: 0.7rem;
  text-align: left;
  color: "#777";
  font-size: 0.8rem;
}
/* Responsive */
@media (max-width: 964) {
  .contact-container {
    margin: 0 auto;
    width: 90%;
  }
}
@media (max-width: 700px) {
  .contact-container {
    gap: 1rem;
  }
  .map {
    width: 85vw;
    height: 55vw;
  }
  .form-container {
    width: auto;
  }
  .contact-container label {
    font-size: 0.7rem;
  }
  .informations {
    padding: 0.5rem;
    width: 40vw;
  }
  .box {
    text-align: center;
  }
  .li i {
    display: none;
  }
  .contact-form input {
    padding: 0.3rem;
    font-size: 0.7rem;
  }
  .contact-form textarea {
    padding: 0.3rem;
    font-size: 0.7rem;
  }
  .contact-row {
    display: inline-block;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  .row-info-contact {
    margin: 0 auto;
    padding: 0 25px;
    margin-bottom: 20px;
  }
  .contact-form .send-button {
    width: 78%;
  }
}
