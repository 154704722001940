.footer {
  background-color: #191919;
  padding: 2rem 0.3rem;
}
.footerContainer {
  max-width: 1170px;
  margin: auto;
}
.row {
  display: flex;
  flex-wrap: wrap;
}
.footerCol {
  width: 25%;
  padding: 0 15px;
}
.footerCol ul {
  list-style: none;
}
.footerCol h4 {
  font-size: 1rem;
  color: #ffffff;
  text-transform: capitalize;
  margin-bottom: 35px;
  font-weight: 500;
  position: relative;
}
.footerCol h4::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -10px;
  background-color: #ffe600;
  height: 2px;
  box-sizing: border-box;
  width: 50px;
}

.footerCol ul li {
  display: flex;
  align-items: center;
  gap: 10px;
}
.footerCol ul li:not(:last-child) {
  margin-bottom: 10px;
}
.footerCol ul li a,
.footerCol ul li span {
  font-size: 0.8rem;
  text-transform: capitalize;
  color: #ffffff;
  text-decoration: none;
  font-weight: 300;
  color: #bbbbbb;
  display: block;
  transition: all 0.3s ease;
}
.footerCol ul li a:hover {
  color: #ffffff;
  padding-left: 8px;
}
.footerCol .socialLinks a {
  display: inline-block;
  height: 2rem;
  width: 2rem;
  background-color: rgba(255, 255, 255, 0.2);
  margin: 0 5px 5px 0;
  text-align: center;
  line-height: 2rem;
  border-radius: 50%;
  color: #ffffff;
  transition: all 0.5s ease;
}
.footerCol .socialLinks a:hover {
  color: #24262b;
  background-color: #ffffff;
}
.icon {
  color: #bbbbbb;
}
.hr {
  margin-top: 2rem;
  margin-bottom: 1rem;
  opacity: 0.3;
}

.subFooter {
  text-align: center;
  color: white;
  opacity: 0.8;
  font-size: 0.7rem;
  line-height: 0.9rem;
  margin-top: 1.5rem;
}

/*responsive*/
@media (max-width: 915px) {
  .footerCol {
    width: 50%;
    margin-bottom: 30px;
  }
}
@media (max-width: 574px) {
  .footerCol {
    width: 100%;
    margin-bottom: 2rem;
  }
}
