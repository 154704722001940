.appBar {
  color: red;
  box-shadow: none;
  text-align: center;
  padding: 1.2rem;
}

.title {
  flex-grow: 1;
  text-align: center;
  color: black;
  opacity: 0.7;
  font-size: 1.5rem;
  font-weight: 500;
}

.divider {
  height: 3px;
  display: block;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 1em 0 1em;
  width: 100%;
  max-width: 30px;
  margin-left: auto;
  margin-right: auto;
}
